import { SwiperContainer, register } from 'swiper/element/bundle'
import React, { useEffect, useRef, useState } from 'react'
import type { SwiperOptions } from 'swiper/types/swiper-options'

register()

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace React.JSX {
    interface IntrinsicElements {
      'swiper-container': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & SwiperOptions,
        HTMLElement
      >
      'swiper-slide': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
    }
  }
}

interface ForaSwiperProps {
  params: SwiperOptions
  className?: string
  withNumberedPagination?: boolean
  customPagination?: boolean
  children: React.ReactNode
  setSwiperRef?: (ref: SwiperContainer) => void
}

const ForaSwiper = ({
  params,
  className,
  children,
  setSwiperRef,
  customPagination = false,
  withNumberedPagination = false,
}: ForaSwiperProps): JSX.Element => {
  const swiperRef = useRef<SwiperContainer>(null)
  const [currentSlide, setCurrentSlide] = useState<number>(1)
  useEffect(() => {
    if (swiperRef.current) {
      if (withNumberedPagination || customPagination) {
        params.on = {
          slideChange(swiper) {
            setCurrentSlide(swiper.activeIndex + 1)
          },
        }
      }
      params.injectStyles = [
        ...(params.injectStyles ?? []),
        `
        .swiper-button-next svg,
        .swiper-button-prev svg {
          display: none; 
        }
        `,
      ]
      Object.assign(swiperRef.current, params)
      swiperRef.current.initialize()
      if (setSwiperRef) {
        setSwiperRef(swiperRef.current)
      }
    }
  }, [])

  const handleNext = () => {
    if (swiperRef) swiperRef.current?.swiper.slideNext()
  }

  const handlePrev = () => {
    if (swiperRef) swiperRef.current?.swiper.slidePrev()
  }
  return (
    <div className={`relative ${className}`}>
      <swiper-container ref={swiperRef} init={false}>
        {Array.isArray(children) &&
          children.map((child, index) => (
            <swiper-slide key={`swiper-slide${index}`}>{child}</swiper-slide>
          ))}
      </swiper-container>
      {customPagination && (
        <div className="flex items-center justify-center lg:hidden custom-pagination-container gap-x-3">
          <button onClick={handlePrev} className="w-6 h-6 button-prev" />
          <div className="flex items-center gap-x-3">
            {Array.from({ length: React.Children.count(children) }, (_, i) => (
              <span
                key={i}
                className={`${i + 1 === currentSlide ? 'underline' : ''}`}
              >
                {i + 1}
              </span>
            ))}
          </div>
          <button onClick={handleNext} className="w-6 h-6 button-next" />
        </div>
      )}
      {withNumberedPagination && (
        <div className="hidden top-[-60px] lg:block slick-steps fora-text-h8">
          {currentSlide} / {React.Children.count(children)}
        </div>
      )}
    </div>
  )
}

export default ForaSwiper

import Image from 'next/image'
import { ComponentImageSideBySide } from 'types/generated/contentful-types'
import CarouselSlider from '../ui/CarouselSlider'

export enum ImagePositionForMobile {
  DEFAULT = 'top',
  ALTERNATE = 'bottom',
}

export type ImageSideBySideProps = Omit<ComponentImageSideBySide, '_id'> & {
  imagePositionForMobile?: string
  loadWithPriority?: boolean
}

export default function ImageSideBySide({
  cloudinaryImageOne,
  cloudinaryImageTwo,
  imageOne,
  imageTwo,
  caption,
  imagePositionForMobile = 'top',
  loadWithPriority = false,
}: ImageSideBySideProps): JSX.Element {
  return (
    <section className="mb-8">
      <div className="lg:hidden">
        <CarouselSlider
          maxItems={1}
          arrowStyle="Default"
          dotsStyle="Dots"
          showNext={true}
        >
          <div
            className={`${
              imagePositionForMobile == ImagePositionForMobile.DEFAULT
                ? 'pb-8'
                : ''
            } lg:order-2 lg:pb-0`}
          >
            <div className="relative w-full h-[360px]">
              <Image
                src={cloudinaryImageOne?.[0].secure_url ?? imageOne?.url ?? ''}
                alt={
                  cloudinaryImageOne?.[0].metadata?.alt ??
                  (imageOne?.description || '')
                }
                priority={loadWithPriority}
                fill
                style={{ objectFit: 'cover' }}
              />
            </div>
          </div>
          <div
            className={`${
              imagePositionForMobile == ImagePositionForMobile.DEFAULT
                ? 'pb-8'
                : ''
            } lg:order-2 lg:pb-0`}
          >
            <div className="relative w-full h-[360px]">
              <Image
                src={cloudinaryImageTwo?.[0].secure_url ?? imageTwo?.url ?? ''}
                alt={
                  cloudinaryImageTwo?.[0].metadata?.alt ??
                  (imageTwo?.description || '')
                }
                priority={loadWithPriority}
                fill
                style={{ objectFit: 'cover' }}
              />
            </div>
          </div>
        </CarouselSlider>
      </div>
      <div className="hidden mb-4 lg:grid lg:grid-cols-2 gap-x-7">
        <div
          className={`${
            imagePositionForMobile == ImagePositionForMobile.DEFAULT
              ? 'pb-8'
              : ''
          } lg:order-2 lg:pb-0`}
        >
          <div className="relative w-full h-[488px]">
            <Image
              src={cloudinaryImageOne?.[0].secure_url ?? imageOne?.url ?? ''}
              alt={
                cloudinaryImageOne?.[0].metadata?.alt ??
                (imageOne?.description || '')
              }
              fill
              style={{ objectFit: 'cover' }}
              priority={loadWithPriority}
            />
          </div>
        </div>
        <div
          className={`${
            imagePositionForMobile == ImagePositionForMobile.DEFAULT
              ? 'pb-8'
              : ''
          } lg:order-2 lg:pb-0`}
        >
          <div className="relative w-full h-[488px]">
            <Image
              src={cloudinaryImageTwo?.[0].secure_url ?? imageTwo?.url ?? ''}
              alt={
                cloudinaryImageTwo?.[0].metadata?.alt ??
                (imageTwo?.description || '')
              }
              priority={loadWithPriority}
              fill
              style={{ objectFit: 'cover' }}
            />
          </div>
        </div>
      </div>
      {caption && (
        <div>
          <p className="text-center fora-text-caption-1 !text-stone">
            {caption}
          </p>
        </div>
      )}
    </section>
  )
}

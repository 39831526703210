import React, { useEffect, useState } from 'react'
import { isEmailValid } from '@/utils/Helpers'
import { Button } from '@/components/ui/Buttons'
import { useRouter } from 'next/router'
import { imageLoader } from '@/utils/ImageLoaders'
import Image from 'next/image'
import { getCookie } from 'cookies-next'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { ComponentForm } from 'types/generated/contentful-types'
import {
  ForaConversionComponentNames,
  getReferralHistory,
  getURLParameters,
  trackConversionEvent,
} from 'analytics/ForaAnalytics'

enum FormState {
  INITIAL = 'initial',
  SUCCESS = 'success',
  ERROR = 'error',
}

export type AdvisorFormProps = Pick<ComponentForm, 'buttonText'> & {
  inputValue?: string
  state?: string
  jotformId?: string
  successMessage?: string
  typeformUrl?: string
  setShowTypeform?: any
  hasSubscriptionCard?: boolean
  conversionComponentName: ForaConversionComponentNames
  typeformPopOutUrl?: string
}

export function AdvisorSignUpForm({
  inputValue,
  state,
  jotformId,
  buttonText,
  successMessage = 'Thanks! Check your inbox for a survey to get off the waitlist.',
  typeformUrl,
  setShowTypeform,
  hasSubscriptionCard = false,
  conversionComponentName,
  typeformPopOutUrl,
}: AdvisorFormProps): JSX.Element {
  const [pageReferralHistory] = useLocalStorage<string[]>('page-referrals', [])
  const [emailAddress, updateEmailAddress] = useState<string>(inputValue || '')
  const [formState, updateFormState] = useState<FormState>(FormState.INITIAL)
  const [validInput, setValidInput] = useState<boolean>(true)
  const isValidInput = isEmailValid(emailAddress)
  const router = useRouter()
  const [fbpCookie, fbcCookie, ttpCookie, hubspotCookie] = [
    getCookie('_fbp'),
    getCookie('_fbc'),
    getCookie('_ttp'),
    getCookie('hubspotutk'),
  ]

  useEffect(() => {
    if (state === 'success') updateFormState(FormState.SUCCESS)
    if (state === 'formError') updateFormState(FormState.ERROR)
    if (state === 'emailError') setValidInput(false)
  }, [state])

  const handleEmail = (value: string) => {
    setValidInput(true)
    updateEmailAddress(value)
  }

  const handleOnBlur = (value: string) => {
    if (value) setValidInput(isEmailValid(value))
  }

  const {
    campaign,
    content,
    fbclid,
    gclid,
    medium,
    source,
    term,
    ttclid,
    referral,
  } = getURLParameters(router.query)

  const submitForm = () => {
    const { foraLastSlug1, foraLastSlug2, foraLastSlug3 } =
      getReferralHistory(pageReferralHistory)

    fetch(`/api/forms/advisor-sign-up`, {
      method: 'post',
      body: JSON.stringify({
        metadata: {
          utm: {
            source,
            medium,
            campaign,
            term,
            content,
          },
          fbclid,
          gclid,
          ttclid,
          fbp: fbpCookie,
          fbc: fbcCookie,
          ttp: ttpCookie,
          hubspotutk: hubspotCookie,
          formPath: router.asPath,
          fora_lastslug1: foraLastSlug1,
          fora_lastslug2: foraLastSlug2,
          fora_lastslug3: foraLastSlug3,
          referral,
        },
        jotformId,
        emailAddress,
      }),
    })
      .then((response) =>
        response.json().then((data) => {
          if (data.status === 'success' && response.status === 200) {
            updateFormState(FormState.SUCCESS)
            trackConversionEvent({
              foraFormEmail: emailAddress,
              componentName: conversionComponentName,
              fbc: fbcCookie?.toString() || '',
              fbp: fbpCookie?.toString() || '',
              hubspotCookie: hubspotCookie?.toString() || '',
              ttp: ttpCookie?.toString() || '',
              foraLastSlug1,
              foraLastSlug2,
              foraLastSlug3,
              fbclid,
              gclid,
              ip: data.request_ip,
              label: 'Advisor Sign Up',
              ttclid,
              pagePath: router.pathname,
              referral,
            })
            if (typeformUrl) setShowTypeform(true)
          } else {
            updateFormState(FormState.ERROR)
          }
        })
      )
      .catch(() => updateFormState(FormState.ERROR))
  }

  const formatHiddenFieldsForTypeform = (baseURL: string) => {
    /*
     * Hidden fields must be configure in Typeform to accept each hidden field.
     * Format: https://foratravel.typeform.com/to/JFVqK8Ne?utm_source=xxxxx&...
     */
    const params = new URLSearchParams()

    const addParamIfString = (key: string, value: any) => {
      if (typeof value === 'string' && value.trim() !== '') {
        params.append(key, value)
      }
    }

    addParamIfString('referral', referral)
    addParamIfString('email', emailAddress)
    addParamIfString('utm_campaign', campaign)
    addParamIfString('utm_content', content)
    addParamIfString('utm_medium', medium)
    addParamIfString('utm_source', source)
    addParamIfString('utm_term', term)
    addParamIfString('ttclid', ttclid)
    addParamIfString('fbclid', fbclid)
    addParamIfString('gclid', gclid)

    return `${baseURL}?${params.toString()}`
  }

  const BUTTON_TEXT = 'Get on the Waitlist'

  return (
    <div>
      <>
        <div className="mb-6">
          <input
            className={`w-full p-5 bg-transparent placeholder-darkStone outline-none border fora-text-button-2 normal-case ${
              !validInput ? 'border-red text-red' : ''
            }`}
            type="text"
            id="advisor-sign-up"
            autoComplete="email"
            value={emailAddress}
            onChange={(event) => handleEmail(event.target.value)}
            onBlur={(event) => handleOnBlur(event.target.value)}
            placeholder="*EMAIL ADDRESS"
          />
          {!validInput && (
            <div className="my-2.5 error-message">
              <p className="input-message">Invalid email address</p>
            </div>
          )}
          {formState === FormState.SUCCESS && !typeformUrl && (
            <div className="flex my-2.5 text-sm justify-center uppercase bg-shell py-1 px-5">
              <div className="mr-2.5 flex align-bottom">
                <Image
                  loader={({ src }) =>
                    imageLoader({
                      src: src,
                      width: 15,
                      quality: 90,
                    })
                  }
                  src="https://media.foratravel.com/image/upload/v1727799161/icon-check_grrkf5.svg"
                  alt="Icon Check"
                  width={15}
                  height={15}
                />
              </div>
              <p className="input-message">
                {successMessage || 'Thank you! your submission has been sent.'}
              </p>
            </div>
          )}
        </div>
        {formState !== FormState.SUCCESS && (
          <div className={`${hasSubscriptionCard ? '' : 'text-center'}`}>
            {typeformPopOutUrl ? (
              // Prevents opening new tab from being blocked in Safari
              <a
                href={formatHiddenFieldsForTypeform(typeformPopOutUrl)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  buttonFn={() => submitForm()}
                  text={buttonText || BUTTON_TEXT}
                  theme="primary"
                  isFull={!hasSubscriptionCard}
                  isDisabled={!isValidInput}
                />
              </a>
            ) : (
              <Button
                buttonFn={() => submitForm()}
                text={buttonText || BUTTON_TEXT}
                theme="primary"
                isFull={!hasSubscriptionCard}
                isDisabled={!isValidInput}
              />
            )}
          </div>
        )}
        {formState === FormState.ERROR && (
          <div className="inline-block my-2.5 error-message">
            <p className="input-message">
              There was an error. Please try again.
            </p>
          </div>
        )}
      </>
    </div>
  )
}

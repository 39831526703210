import Link from 'next/link'
import { imageLoader } from '@/utils/ImageLoaders'
import Image from 'next/image'

export type PaginationProps = {
  pageUrl?: string
  queryString?: { [key: string]: string | string[] | undefined }
  totalPages: string
  currentPage: string
  prevDisabled: boolean
  nextDisabled: boolean
  type?: string
  onPreviousClick?: () => void
  onNextClick?: () => void
  goToPage?: (page: number) => void
  setNewCurrentSlide?: (newCurrentSlide: number) => any
  showArrows?: boolean
}

export default function Pagination({
  pageUrl,
  queryString,
  totalPages,
  currentPage,
  prevDisabled,
  nextDisabled,
  type,
  onPreviousClick,
  onNextClick,
  goToPage,
  setNewCurrentSlide,
  showArrows = true,
}: PaginationProps) {
  const pageQueryString = queryString
    ? Object.keys(queryString)
        .filter((q) => q !== 'p')
        .map((q) => `${q}=${queryString[q]}`)
        .join('&')
    : ''

  const prevPageUrl = `/${pageUrl}${type ? `/${type}` : ''}?p=${
    parseInt(currentPage) - 1
  }${pageQueryString.length > 0 ? `&${pageQueryString}` : ''}`

  const nextPageUrl = `/${pageUrl}${type ? `/${type}` : ''}?p=${
    parseInt(currentPage) + 1
  }${pageQueryString.length > 0 ? `&${pageQueryString}` : ''}`

  const toPage = (page: string) => {
    return `/${pageUrl}${type ? `/${type}` : ''}?p=${page}${
      pageQueryString.length > 0 ? `&${pageQueryString}` : ''
    }`
  }

  const pages: string[] = []
  for (let page = 1; page <= parseInt(totalPages); page++) {
    pages.push(page.toString())
  }

  const onPrev = () => {
    onPreviousClick ? onPreviousClick() : null
    setNewCurrentSlide ? setNewCurrentSlide(parseInt(currentPage) - 1) : null
  }

  const onNext = () => {
    onNextClick ? onNextClick() : null
    setNewCurrentSlide ? setNewCurrentSlide(parseInt(currentPage) + 1) : null
  }

  const onToPage = (page: string) => {
    goToPage ? goToPage(parseInt(page)) : null
    setNewCurrentSlide ? setNewCurrentSlide(parseInt(page) + 1) : null
  }

  return (
    <ol className="flex justify-center">
      {showArrows && (
        <li className="mr-4">
          {onPreviousClick ? (
            <div
              className={`${
                prevDisabled ? 'cursor-default	' : 'cursor-pointer'
              }`}
              onClick={!prevDisabled ? onPrev : undefined}
              onKeyDown={!prevDisabled ? onPrev : undefined}
              role="button"
              tabIndex={0}
            >
              <PaginationButton disable={prevDisabled} side="left" />
            </div>
          ) : !prevDisabled ? (
            <Link href={prevPageUrl}>
              <PaginationButton disable={prevDisabled} side="left" />
            </Link>
          ) : (
            <PaginationButton disable={prevDisabled} side="left" />
          )}
        </li>
      )}
      <span className="flex gap-4">
        {pages.length <= 7
          ? pages.map((page, pageId) => (
              <PaginationButtonNumber
                key={`PageIndex_${pageId}`}
                page={page}
                currentPage={currentPage}
                toPage={goToPage ? onToPage : toPage}
                type={`${goToPage ? 'slider' : 'href'}`}
              />
            ))
          : parseInt(currentPage) < 5
          ? pages.map((page, pageId) =>
              parseInt(page) <= 5 ? (
                <PaginationButtonNumber
                  key={`PageIndex_${pageId}`}
                  page={page}
                  currentPage={currentPage}
                  toPage={goToPage ? onToPage : toPage}
                  type={`${goToPage ? 'slider' : 'href'}`}
                />
              ) : (
                pages.length == parseInt(page) && (
                  <>
                    <Image
                      className="align-bottom"
                      loader={({ src }) =>
                        imageLoader({
                          src: src,
                          width: 10,
                          quality: 90,
                        })
                      }
                      src="https://media.foratravel.com/image/upload/v1727438834/dots_h6ombf.svg"
                      alt="Icon Dots"
                      width={10}
                      height={4}
                    />
                    <PaginationButtonNumber
                      key={`PageIndex_${pageId}`}
                      page={page}
                      currentPage={currentPage}
                      toPage={goToPage ? onToPage : toPage}
                      type={`${goToPage ? 'slider' : 'href'}`}
                    />
                  </>
                )
              )
            )
          : parseInt(currentPage) > pages.length - 4
          ? pages.map((page, pageId) =>
              parseInt(page) > pages.length - 5 ? (
                <PaginationButtonNumber
                  key={`PageIndex_${pageId}`}
                  page={page}
                  currentPage={currentPage}
                  toPage={goToPage ? onToPage : toPage}
                  type={`${goToPage ? 'slider' : 'href'}`}
                />
              ) : (
                parseInt(page) === 1 && (
                  <>
                    <PaginationButtonNumber
                      key={`PageIndex_${pageId}`}
                      page={page}
                      currentPage={currentPage}
                      toPage={goToPage ? onToPage : toPage}
                      type={`${goToPage ? 'slider' : 'href'}`}
                    />
                    <Image
                      className="align-bottom"
                      loader={({ src }) =>
                        imageLoader({
                          src: src,
                          width: 10,
                          quality: 90,
                        })
                      }
                      src="https://media.foratravel.com/image/upload/v1727438834/dots_h6ombf.svg"
                      alt="Icon Dots"
                      width={10}
                      height={4}
                    />
                  </>
                )
              )
            )
          : pages.map((page, pageId) => {
              return (
                <>
                  {parseInt(page) === 1 && (
                    <>
                      <PaginationButtonNumber
                        key={`PageIndex_${pageId}`}
                        page={page}
                        currentPage={currentPage}
                        toPage={goToPage ? onToPage : toPage}
                        type={`${goToPage ? 'slider' : 'href'}`}
                      />
                      <Image
                        className="align-bottom"
                        loader={({ src }) =>
                          imageLoader({
                            src: src,
                            width: 10,
                            quality: 90,
                          })
                        }
                        src="https://media.foratravel.com/image/upload/v1727438834/dots_h6ombf.svg"
                        alt="Icon Dots"
                        width={10}
                        height={4}
                      />
                    </>
                  )}
                  {(parseInt(page) === parseInt(currentPage) ||
                    parseInt(page) === parseInt(currentPage) - 1 ||
                    parseInt(page) === parseInt(currentPage) + 1) && (
                    <PaginationButtonNumber
                      key={`PageIndex_${pageId}`}
                      page={page}
                      currentPage={currentPage}
                      toPage={goToPage ? onToPage : toPage}
                      type={`${goToPage ? 'slider' : 'href'}`}
                    />
                  )}
                  {pages.length == parseInt(page) && (
                    <>
                      <Image
                        className="align-bottom"
                        loader={({ src }) =>
                          imageLoader({
                            src: src,
                            width: 10,
                            quality: 90,
                          })
                        }
                        src="https://media.foratravel.com/image/upload/v1727438834/dots_h6ombf.svg"
                        alt="Icon Dots"
                        width={10}
                        height={4}
                      />
                      <PaginationButtonNumber
                        key={`PageIndex_${pageId}`}
                        page={page}
                        currentPage={currentPage}
                        toPage={goToPage ? onToPage : toPage}
                        type={`${goToPage ? 'slider' : 'href'}`}
                      />
                    </>
                  )}
                </>
              )
            })}
      </span>
      {showArrows && (
        <li className="ml-4">
          {onNextClick ? (
            <div
              className={`${
                nextDisabled ? 'cursor-default	' : 'cursor-pointer'
              }`}
              onKeyDown={!nextDisabled ? onNext : undefined}
              onClick={!nextDisabled ? onNext : undefined}
              role="button"
              tabIndex={0}
            >
              <PaginationButton disable={nextDisabled} side="right" />
            </div>
          ) : !nextDisabled ? (
            <Link href={nextPageUrl}>
              <PaginationButton disable={nextDisabled} side="right" />
            </Link>
          ) : (
            <PaginationButton disable={nextDisabled} side="right" />
          )}
        </li>
      )}
    </ol>
  )
}

type PaginationButtonNumberProps = {
  page: string
  currentPage: string
  toPage: any
  type: 'href' | 'slider'
}
export function PaginationButtonNumber({
  currentPage,
  page,
  toPage,
  type,
}: PaginationButtonNumberProps) {
  return (
    <>
      {type === 'href' ? (
        <Link href={toPage(page)}>
          <li className={`${currentPage == page ? 'underline' : ''}`}>
            {page}
          </li>
        </Link>
      ) : (
        <div
          className="cursor-pointer"
          onClick={() => toPage(parseInt(page) - 1)}
          onKeyDown={() => toPage(parseInt(page) - 1)}
          role="button"
          tabIndex={0}
        >
          <li className={`${currentPage == page ? 'underline' : ''}`}>
            {page}
          </li>
        </div>
      )}
    </>
  )
}

export type PaginationButtonProps = {
  side: 'left' | 'right'
  disable?: boolean
}

export function PaginationButton({
  side,
  disable = false,
}: PaginationButtonProps) {
  return (
    <div
      className={`flex justify-center w-6 h-6 border rounded-full ${
        disable ? 'opacity-0' : ''
      }`}
    >
      <Image
        className="align-bottom"
        loader={({ src }) =>
          imageLoader({
            src: src,
            width: 10,
            quality: 90,
          })
        }
        src={
          side === 'right'
            ? 'https://media.foratravel.com/image/upload/v1727798794/icon-arrow-right_ryvkvs.svg'
            : 'https://media.foratravel.com/image/upload/v1727798569/icon-arrow-left_gickbd.svg'
        }
        alt={side === 'right' ? 'Icon Arrow Right' : 'Icon Arrow Left'}
        width={10}
        height={10}
      />
    </div>
  )
}

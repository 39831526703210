import { ComponentForm } from 'types/generated/contentful-types'
import { AdvisorContactForm } from '../forms/AdvisorContactForm'
import { AdvisorSignUpForm } from '../forms/AdvisorSignUpForm'
import { GetMatchedForm } from '../forms/GetMatchedForm'
import GetOnWaitlist from '../forms/GetOnWaitlist'
import { NewsletterSignUpForm } from '../forms/NewsletterSignUpForm'
import { getDefaultAdvisor } from '@/utils/fixtures/PartnerDetailContent'
import { Modal } from '../common/Modal'
import { useState } from 'react'
import { AdvisorSignUpSuccessModal } from '../forms/AdvisorSignUpSuccessModal'
import { getAdvisorDisplayName } from '@/utils/Helpers'
import { ForaConversionComponentNames } from 'analytics/ForaAnalytics'

export type FormProps = ComponentForm & {
  jotformId?: string
  buttonText?: string
  typeformUrl?: string
  typeformPopOutUrl?: string
  conversionComponentName: ForaConversionComponentNames
}

const Form = ({
  formId,
  sectionId,
  jotformId,
  typeformUrl,
  typeformPopOutUrl,
  zapierId,
  title,
  titleSharedState,
  teasercopy,
  subcopy,
  subcopySharedState,
  buttonText,
  buttonTextSharedState,
  subscriptionCard,
  conversionComponentName,
  successText,
}: FormProps): JSX.Element => {
  const [showingSubmitFormModal, setShowingSubmitFormModal] =
    useState<boolean>(false)
  const toggleShowingSubmitFormModal = () => {
    setShowingSubmitFormModal((showing) => !showing)
  }

  return (
    <div id={sectionId || undefined}>
      {renderForm({
        jotformId,
        formId,
        showingSubmitFormModal,
        toggleShowingSubmitFormModal,
        typeformUrl,
        typeformPopOutUrl,
        zapierId,
        title,
        titleSharedState,
        teasercopy,
        subcopy,
        subcopySharedState,
        buttonText,
        buttonTextSharedState,
        subscriptionCard,
        conversionComponentName,
        successText,
      })}
    </div>
  )
}

type RenderFormArgs = Omit<FormProps, 'sys' | 'contentfulMetadata' | '_id'> & {
  showingSubmitFormModal: boolean
  toggleShowingSubmitFormModal: (showing: boolean) => void
  conversionComponentName: ForaConversionComponentNames
}

const renderForm = ({
  jotformId,
  formId,
  showingSubmitFormModal,
  toggleShowingSubmitFormModal,
  typeformUrl,
  typeformPopOutUrl,
  zapierId,
  title,
  titleSharedState,
  teasercopy,
  subcopy,
  subcopySharedState,
  buttonText,
  buttonTextSharedState,
  subscriptionCard,
  conversionComponentName,
  successText,
}: RenderFormArgs) => {
  const isDefaultAdvisor = true
  const advisor = getDefaultAdvisor()
  const advisorDisplayName = getAdvisorDisplayName(
    isDefaultAdvisor ? 'us' : advisor.title,
    advisor.firstName
  )
  const displayTitle =
    titleSharedState?.replace('{name}', advisorDisplayName) || title || ''
  const displayTeaserCopy = teasercopy || ''
  const displaySubcopy =
    subcopySharedState?.replace('{name}', advisorDisplayName) || subcopy || ''
  const displayButtonText =
    buttonTextSharedState?.replace('{name}', advisorDisplayName) ||
    buttonText ||
    ''

  switch (formId) {
    case 'advisorContact':
      return (
        <>
          <AdvisorContactForm
            {...advisor}
            conversionComponentName={conversionComponentName}
            heading={displayTitle}
            subheading={displayTeaserCopy}
            subcopy={displaySubcopy}
            submitButtonText={displayButtonText}
            onSubmit={() => {
              toggleShowingSubmitFormModal(!showingSubmitFormModal)
            }}
          />
          <Modal
            onClose={() =>
              toggleShowingSubmitFormModal(!showingSubmitFormModal)
            }
            visible={showingSubmitFormModal}
          >
            <AdvisorSignUpSuccessModal
              onClose={() =>
                toggleShowingSubmitFormModal(!showingSubmitFormModal)
              }
              displayName={getAdvisorDisplayName(
                getDefaultAdvisor().title,
                getDefaultAdvisor().firstName
              )}
            />
          </Modal>
        </>
      )
    case 'matchMe':
      return (
        <GetMatchedForm
          conversionComponentName={conversionComponentName}
          title={title}
          teasercopy={teasercopy}
          buttonText={buttonText}
          successText={
            successText ||
            'Thanks! Check your inbox for a survey to get off the waitlist.'
          }
        />
      )
    case 'newsletter':
      return (
        <NewsletterSignUpForm
          conversionComponentName={conversionComponentName}
          jotformId={zapierId || jotformId}
          buttonText={buttonText}
          successText={
            successText ||
            'Thanks! Check your inbox for a survey to get off the waitlist.'
          }
        />
      )
    case 'advisorSignUp':
      return (
        <AdvisorSignUpForm
          conversionComponentName={conversionComponentName}
          jotformId={zapierId || jotformId}
          typeformPopOutUrl={typeformPopOutUrl}
          buttonText={buttonText}
          successMessage={
            successText ||
            'Thanks! Check your inbox for a survey to get off the waitlist.'
          }
        />
      )
    case 'getOnWaitlist':
      return (
        <GetOnWaitlist
          conversionComponentName={conversionComponentName}
          zapierId={zapierId ?? undefined}
          title={title}
          teasercopy={teasercopy}
          buttonText={buttonText}
          typeformUrl={typeformUrl}
          typeformPopOutUrl={typeformPopOutUrl}
          subscriptionCard={subscriptionCard}
          successText={successText}
        />
      )
  }
}

export default Form

import { ForaWindow } from 'types/global'
declare let window: ForaWindow

interface AnalyticsURLParams {
  utm_campaign?: string
  utm_content?: string
  fbclid?: string
  gclid?: string
  utm_medium?: string
  utm_source?: string
  utm_term?: string
  ttclid?: string
  referral?: string
}

export const getURLParameters = (params: AnalyticsURLParams) => {
  return {
    campaign: params?.utm_campaign || '',
    content: params?.utm_content || '',
    fbclid: params?.fbclid || '',
    gclid: params?.gclid || '',
    medium: params?.utm_medium || '',
    source: params?.utm_source || '',
    term: params?.utm_term || '',
    ttclid: params?.ttclid || '',
    referral: params?.referral || '',
  }
}

interface PageReferralHistory {
  foraLastSlug1: string
  foraLastSlug2: string
  foraLastSlug3: string
}

export const getReferralHistory = (history: string[]): PageReferralHistory => {
  return {
    foraLastSlug1: history[history.length - 2] || '',
    foraLastSlug2: history[history.length - 3] || '',
    foraLastSlug3: history[history.length - 4] || '',
  }
}

type ForaConversionPages =
  | 'Advisor Page'
  | 'Blog Type Page'
  | 'Destinations Page'
  | 'Guide Page'
  | 'Partner Detail Page'
  | 'Partner Landing Page'
  | 'Profile Page'
  | 'Styles Page'
  | 'Trip Reports Guides Page'
  | 'Trip Report Page'
  | 'Event Detail Page'

type ForaConversionComponents =
  | 'Advisor Contact CTA Mobile'
  | 'Advisor Contact CTA Wide'
  | 'Advisor Contact CTA'
  | 'Advisor Contact Disabled CTA'
  | 'Contact Form'
  | 'Header'
  | 'Hero Block'
  | 'Pricing Models'
  | 'Subscription Form'
  | 'Travel By Sub Category'
  | 'Travel By'
  | 'Typeform Embed'

export type ForaConversionComponentNames =
  | ForaConversionComponents
  | ForaConversionPages

export type ForaConversionLabel =
  | 'Advisor Contact'
  | 'Advisor Match'
  | 'Advisor Mailing List Sign Up'
  | 'Advisor Sign Up'
  | 'Newsletter Sign Up'
  | 'Wait List Sign Up'
  | 'Event Registration'
  | 'Typeform Submission'

/*
ForaConversionEvent - directly maps to data layer variables in
Google Tag Manager

Any changes to this event should also be made in Fora's "Fora Analytics.js"
HubSpot module to ensure consistency in analytics data
*/
interface ForaConversionEvent {
  event: 'fora_conversion'
  fora_advisor_name: string
  fora_conversion_ip: string
  fora_conversion_component: string
  fora_conversion_label: ForaConversionLabel
  fora_form_email: string
  fora_form_first_name: string
  fora_form_last_name: string
  fora_form_comments: string
  fora_fbc: string
  fora_fbp: string
  fora_gclid: string
  fora_fbclid: string
  fora_hubspotutk: string
  fora_last_slug_1: string
  fora_last_slug_2: string
  fora_last_slug_3: string
  fora_page_path: string
  fora_ttclid: string
  fora_ttp: string
  fora_sequel_event_id: string
  fora_event_registration_session_id: string
  fora_sequel_event_name: string
  fora_advisor_referral: string
  fora_typeform_response_id: string
  fora_typeform_form_id: string
  fora_referral: string
  [key: string]: unknown | ForaConversionLabel // Index signature
}

export interface TrackConversionEventParams {
  advisorName?: string
  componentName: ForaConversionComponentNames
  foraFormEmail?: string
  foraFormFirstName?: string
  foraFormLastName?: string
  foraFormComments?: string
  fbc: string
  fbclid: string
  fbp: string
  foraLastSlug1: string
  foraLastSlug2: string
  foraLastSlug3: string
  gclid: string
  hubspotCookie: string
  ip: string
  label: ForaConversionLabel
  pagePath: string
  ttclid: string
  ttp: string
  sequelEventId?: string
  registrationSessionId?: string
  registrationEventName?: string
  referral?: string
  typeformResponseId?: string
  typeformFormId?: string
}

export const trackConversionEvent = (data: TrackConversionEventParams) => {
  const EVENT = 'fora_conversion'
  window.dataLayer = window.dataLayer || []

  const conversionEvent: ForaConversionEvent = {
    event: EVENT,
    fora_advisor_name: data?.advisorName || '',
    fora_conversion_component: data?.componentName || '',
    fora_conversion_ip: data?.ip || '',
    fora_conversion_label: data?.label,
    fora_form_email: data?.foraFormEmail || '',
    fora_form_first_name: data?.foraFormFirstName || '',
    fora_form_last_name: data?.foraFormLastName || '',
    fora_form_comments: data?.foraFormComments || '',
    fora_fbc: data?.fbc || '',
    fora_fbp: data?.fbp || '',
    fora_gclid: data?.gclid || '',
    fora_fbclid: data?.fbclid || '',
    fora_hubspotutk: data?.hubspotCookie || '',
    fora_last_slug_1: data?.foraLastSlug1 || '',
    fora_last_slug_2: data?.foraLastSlug2 || '',
    fora_last_slug_3: data?.foraLastSlug3 || '',
    fora_page_path: data?.pagePath || '',
    fora_ttclid: data?.ttclid || '',
    fora_ttp: data?.ttp || '',
    fora_sequel_event_id: data?.sequelEventId || '',
    fora_event_registration_session_id: data?.registrationSessionId || '',
    fora_sequel_event_name: data?.registrationEventName || '',
    fora_advisor_referral: data?.referral || '',
    fora_typeform_response_id: data?.typeformResponseId || '',
    fora_typeform_form_id: data?.typeformFormId || '',
    fora_referral: data?.referral || '',
  }
  window.dataLayer.push(conversionEvent)
}

import Link from 'next/link'
import { ButtonThemeWrapper } from '@/components/ui/ButtonElements'

type LinkWrapperProps = {
  href: string | { pathname: string; query: { [key: string]: string } }
  target: '_self' | '_blank'
  children: React.ReactNode
  isFull?: boolean
  prefetch?: boolean
  destinationClass?: boolean
}

export type ButtonLinkProps = {
  customClass?: string
  href?: string | { pathname: string; query: { [key: string]: string } }
  target: '_self' | '_blank'
  showIcon?: boolean
  text: string
  theme: 'primary' | 'secondary' | 'transparent' | 'transparentLight' | 'light'
  isFull?: boolean
  isModal?: boolean
  destinationClass?: boolean
  prefetch?: boolean
  onClose?: any
}

function LinkWrapper({
  href,
  target,
  children,
  isFull = false,
  prefetch = true,
  destinationClass = false,
}: LinkWrapperProps): JSX.Element {
  return (
    <Link
      href={href}
      prefetch={prefetch ? undefined : false}
      target={target}
      className={`${isFull ? 'w-full' : ''} ${
        destinationClass ? 'contents' : ''
      }`}
      data-name="link-wrapper"
    >
      {children}
    </Link>
  )
}

export function ButtonLink({
  customClass,
  href,
  target,
  showIcon,
  text,
  theme,
  isFull = false,
  isModal = false,
  prefetch = true,
  onClose,
  destinationClass,
}: ButtonLinkProps): JSX.Element {
  const defaultStyles =
    'uppercase whitespace-nowrap fora-text-button-1 py-4 lg:py-[14px] px-4'

  return !isModal && href ? (
    <LinkWrapper
      isFull={isFull}
      href={href}
      target={target}
      prefetch={prefetch}
      destinationClass={destinationClass}
    >
      <ButtonThemeWrapper theme={theme} showIcon={showIcon} isFull={isFull}>
        <span className={`${customClass ? customClass : ''} ${defaultStyles}`}>
          {text}
        </span>
      </ButtonThemeWrapper>
    </LinkWrapper>
  ) : (
    <ButtonThemeWrapper
      theme={theme}
      showIcon={showIcon}
      isFull={isFull}
      data-name="button-wrapper"
    >
      <button
        className={`${customClass ? customClass : ''} ${
          isFull ? 'w-full' : ''
        } ${defaultStyles}`}
        onClick={() => onClose()}
        onKeyDown={() => onClose()}
      >
        {text}
      </button>
    </ButtonThemeWrapper>
  )
}

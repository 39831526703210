import React, { useEffect, useRef, useState } from 'react'
import Slider, { InnerSlider } from 'react-slick'
import { imageLoader } from '@/utils/ImageLoaders'
import Image from 'next/image'
import Pagination, { PaginationButton } from './Pagination'

interface SliderArrowProps {
  onClick?: any
  className?: string
}

interface CustomInnerSliderProps extends InnerSlider {
  props?: any
}

function NextArrow(props: SliderArrowProps) {
  const { className, onClick } = props
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={`${className} absolute right-0 z-10 w-6 h-6 lg:flex justify-center items-center border rounded-full -top-13`}
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      <Image
        loader={({ src }) =>
          imageLoader({
            src: src,
            width: 10,
            quality: 90,
          })
        }
        src="https://media.foratravel.com/image/upload/v1727798794/icon-arrow-right_ryvkvs.svg"
        alt="Next slide"
        fill
      />
    </div>
  )
}

function PrevArrow(props: SliderArrowProps) {
  const { className, onClick } = props
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={`${className} absolute lg:flex justify-center items-center right-9 z-10 w-6 h-6 border rounded-full -top-13`}
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      <Image
        className="align-bottom"
        loader={({ src }) =>
          imageLoader({
            src: src,
            width: 10,
            quality: 90,
          })
        }
        src="https://media.foratravel.com/image/upload/v1727798569/icon-arrow-left_gickbd.svg"
        alt="Previous slide"
        fill
      />
    </div>
  )
}

function NextRightArrow(props: SliderArrowProps) {
  const { className, onClick } = props
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={`${className} absolute -left-10 z-10 w-6 h-6 lg:flex justify-center items-center top-1/2 hidden`}
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      <Image
        loader={({ src }) =>
          imageLoader({
            src: src,
            width: 24,
            quality: 90,
          })
        }
        src="https://media.foratravel.com/image/upload/v1727798677/icon-arrow-line-left_eyzlgj.svg"
        alt="Previous slide"
        fill
      />
    </div>
  )
}

function PrevLeftArrowComponent(props: SliderArrowProps, ref: any) {
  const { className, onClick } = props
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      ref={ref}
      className={`${className} absolute lg:flex justify-center items-center -right-10 z-10 w-6 h-6 top-1/2 hidden`}
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      <Image
        loader={({ src }) =>
          imageLoader({
            src: src,
            width: 24,
            quality: 90,
          })
        }
        src="https://media.foratravel.com/image/upload/v1727798748/icon-arrow-line-right_tks6pt.svg"
        alt="Next slide"
        fill
      />
    </div>
  )
}
const PrevLeftArrow = React.forwardRef(PrevLeftArrowComponent)

export interface CarouselSliderProps {
  maxItems?: number
  // A simple one-off solution. When refactoring this component with the new package, also consider a more generalized
  // and robust handling of the two props below.
  maxItemsTablet?: number
  maxItemsMobile?: number
  children?: React.ReactNode
  // This prop is difficult to use responsively and doesn't seem to work well on certain screen sizes. Alternatively,
  // provide a float to `maxItemsMobile` or `maxItemsTablet` to achieve the same effect.
  showNext?: boolean
  auto?: boolean
  infinite?: boolean
  centerMode?: boolean
  withController?: boolean
  showControls?: boolean
  arrowStyle?: string
  dotsStyle?: string
  centerPadding?: string
  onChange?: () => void
}

export default function CarouselSlider({
  maxItems = 2,
  maxItemsTablet,
  maxItemsMobile,
  children,
  showNext = false,
  arrowStyle,
  dotsStyle,
  centerPadding,
  auto = false,
  infinite = false,
  withController = false,
  centerMode = false,
  onChange,
  showControls = false,
}: CarouselSliderProps): JSX.Element {
  const [currentSlide, setCurrentSlide] = useState<number>(0)
  const slider = useRef<Slider | null>(null)
  const defaultArrowRef = useRef<any>()
  useEffect(() => {
    setCurrentSlide(1)
  }, [])

  const slidesToShow = maxItems
  const slidesToShow1024 =
    maxItemsTablet ?? (maxItems > 1 ? maxItems - 1 : maxItems)
  const slidesToShow600 = maxItemsMobile ?? 3
  const slidesToShow480 =
    maxItemsMobile ?? (maxItems > 1 ? maxItems - 1 : maxItems)

  const prevDisabled = false
  const nextDisabled = slider.current
    ? currentSlide ===
      Math.ceil(
        (slider?.current?.props.children as React.ReactNode[]).length || 0
      )
    : true

  const onPrev = () => {
    slider.current && slider.current.slickPrev
      ? slider.current && slider.current.slickPrev()
      : null
    setCurrentSlide(currentSlide - 1)
  }

  const onNext = () => {
    slider.current && slider.current.slickNext
      ? slider.current && slider.current.slickNext()
      : null
    setCurrentSlide(currentSlide + 1)
  }

  const settings = {
    centerMode: centerMode || showNext,
    centerPadding: centerPadding || '50px',
    pauseOnHover: false,
    dots: withController,
    appendDots: (dots) => {
      return dotsStyle === 'Dots' ? (
        <>
          {auto ? (
            <div className="customDots ">{dots}</div>
          ) : (
            <ul className="flex justify-center w-full md:pt-4">
              {arrowStyle === 'Compact' && (
                <li className="hidden mr-4 md:block">
                  {slider.current && (
                    <div
                      className={`${
                        prevDisabled ? 'cursor-default	' : 'cursor-pointer'
                      }`}
                      onClick={!prevDisabled ? onPrev : undefined}
                      onKeyDown={!prevDisabled ? onPrev : undefined}
                      role="button"
                      tabIndex={0}
                    >
                      <PaginationButton disable={prevDisabled} side="left" />
                    </div>
                  )}
                </li>
              )}
              {dots.map((item, index) => {
                return (
                  <li key={index} className="pr-1 list-none">
                    {slider.current && (
                      <button
                        className={`${
                          prevDisabled ? 'cursor-default' : 'cursor-pointer'
                        }`}
                        onClick={() => setCurrentSlide(index)}
                        onKeyDown={!prevDisabled ? onPrev : undefined}
                        tabIndex={0}
                      >
                        <Image
                          loader={({ src }) =>
                            imageLoader({
                              src: src,
                              width: 8,
                              quality: 8,
                            })
                          }
                          src={
                            index === Math.ceil(currentSlide) - 1
                              ? 'https://media.foratravel.com/image/upload/v1727797205/ellipse-active_jubl16.svg'
                              : 'https://media.foratravel.com/image/upload/v1715877378/ellipse_vnnowt.svg'
                          }
                          alt="Ellipse Icon"
                          width={8}
                          height={8}
                        />
                      </button>
                    )}
                  </li>
                )
              })}
              {arrowStyle === 'Compact' && (
                <li className="hidden ml-4 md:block">
                  {slider.current && !auto && (
                    <div
                      className={`${
                        nextDisabled ? 'cursor-default	' : 'cursor-pointer'
                      }`}
                      onKeyDown={!nextDisabled ? onNext : undefined}
                      onClick={!nextDisabled ? onNext : undefined}
                      role="button"
                      tabIndex={0}
                    >
                      <PaginationButton disable={nextDisabled} side="right" />
                    </div>
                  )}
                </li>
              )}
            </ul>
          )}
        </>
      ) : dotsStyle === 'Numbers' ? (
        React.Children.count(children) > 1 ? (
          <>
            <div className="pt-6 md:pt-8">
              <Pagination
                totalPages={
                  slider.current
                    ? Math.ceil(
                        (slider?.current?.props?.children as React.ReactNode[])
                          .length
                      ).toString()
                    : '1'
                }
                setNewCurrentSlide={(newCurrentSlide: number) => {
                  setCurrentSlide(newCurrentSlide)
                }}
                goToPage={
                  (slider.current && slider.current.slickGoTo) || undefined
                }
                onPreviousClick={
                  (slider.current && slider.current.slickPrev) || undefined
                }
                onNextClick={
                  (slider.current && slider.current.slickNext) || undefined
                }
                currentPage={currentSlide.toString()}
                prevDisabled={prevDisabled}
                nextDisabled={nextDisabled}
                showArrows={arrowStyle === 'Compact'}
              />
            </div>
          </>
        ) : (
          <></>
        )
      ) : (
        <></>
      )
    },
    infinite: infinite,
    fade: auto,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: auto,
    autoplaySpeed: 4000,
    swipe: !auto,
    arrows: React.Children.count(children) > slidesToShow,
    nextArrow:
      arrowStyle === 'Default' ? (
        <PrevLeftArrow ref={defaultArrowRef} />
      ) : arrowStyle === 'Compact' ? (
        <></>
      ) : (
        <NextArrow />
      ),
    prevArrow:
      arrowStyle === 'Default' ? (
        <NextRightArrow />
      ) : arrowStyle === 'Compact' ? (
        <></>
      ) : (
        <PrevArrow />
      ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: slidesToShow1024,
          slidesToScroll: 1,
          centerMode: centerMode || showNext,
          dots: true,
          arrows: React.Children.count(children) > slidesToShow1024,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: slidesToShow600,
          slidesToScroll: slidesToShow600,
          centerMode: showNext,
          dots: true,
          arrows: React.Children.count(children) > slidesToShow600,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: slidesToShow480,
          slidesToScroll: 1,
          centerMode: showNext,
          dots: true,
          arrows: React.Children.count(children) > slidesToShow480,
        },
      },
    ],
  }

  const innerSlider: CustomInnerSliderProps | undefined =
    slider?.current?.innerSlider

  const sliderProps = innerSlider?.props

  // Shorten width if default arrows are visible
  const widthClass =
    arrowStyle === 'Default' && defaultArrowRef.current
      ? 'mx-auto lg:w-[92%]' // Default arrow is not visible on mobile
      : 'w-full'
  return (
    <section className={`max-w-[100vw] ${widthClass} relative`}>
      <Slider
        {...settings}
        ref={slider}
        afterChange={(currentSlide) => {
          setCurrentSlide(currentSlide + 1)
        }}
        beforeChange={onChange}
      >
        {children}
      </Slider>
      {showControls &&
        sliderProps?.children.length > sliderProps?.slidesToShow && (
          <div className="hidden top-[-50px] lg:block slick-steps fora-text-h8">
            {currentSlide} / {React.Children.count(children)}
          </div>
        )}
    </section>
  )
}

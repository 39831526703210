import Image from 'next/image'
import { ButtonLink } from '@/components/ui/ButtonLinks'
import { RichTextContent } from '@/components/design/RichTextContent'
import { AdvisorSignUpForm } from '@/components/forms/AdvisorSignUpForm'
import { PagesHeroBlock } from 'types/generated/contentful-types'
import { NewsletterSignUpForm } from '../forms/NewsletterSignUpForm'

export enum ImagePositionForMobile {
  DEFAULT = 'top',
  ALTERNATE = 'bottom',
}

export type HeroBlockProps = Omit<
  PagesHeroBlock,
  'contentfulMetadata' | 'sys'
> & {
  bigHeadline: boolean
  imagePositionForMobile: ImagePositionForMobile
  jotformId?: string
  loadWithPriority?: boolean
  border?: boolean
}
export default function HeroBlock({
  title,
  image,
  cloudinaryImage,
  teaserCopy,
  showFormAsPrimaryCta,
  primaryCtaLinkText,
  primaryCtaLinkUrl,
  primaryUrlTarget,
  secondaryUrlTarget,
  secondaryCtaLinkText,
  secondaryCtaLinkUrl,
  subFormHeader,
  subFormCopy,
  bigHeadline = false,
  imagePositionForMobile = ImagePositionForMobile.DEFAULT,
  jotformId,
}: HeroBlockProps): JSX.Element {
  const headlineStyle = `fora-text-${bigHeadline ? 'h1' : 'h2'}`

  const renderForm = () => {
    switch (showFormAsPrimaryCta) {
      case 'None':
        return (
          <>
            {primaryCtaLinkText && primaryCtaLinkUrl && (
              <span className="block pb-4 lg:inline lg:pb-0 lg:pr-4">
                <ButtonLink
                  href={primaryCtaLinkUrl}
                  target={(primaryUrlTarget as '_self' | '_blank') || '_self'}
                  text={primaryCtaLinkText}
                  theme="primary"
                />
              </span>
            )}
            {secondaryCtaLinkText && secondaryCtaLinkUrl && (
              <ButtonLink
                href={secondaryCtaLinkUrl}
                target={(secondaryUrlTarget as '_self' | '_blank') || '_self'}
                text={secondaryCtaLinkText}
                theme="secondary"
              />
            )}
          </>
        )
      case 'Advisor Sign-up':
        return (
          <div className="pt-2 lg:w-3/4">
            <AdvisorSignUpForm
              conversionComponentName="Hero Block"
              jotformId={jotformId}
              buttonText={primaryCtaLinkText || ''}
            />
          </div>
        )
      case 'Newsletter Sign-up':
        return (
          <div className="pt-2 lg:w-3/4">
            <NewsletterSignUpForm
              conversionComponentName="Hero Block"
              jotformId={jotformId}
            />
          </div>
        )
      default:
        return (
          <>
            {primaryCtaLinkText && primaryCtaLinkUrl && (
              <span className="block pb-4 lg:inline lg:pb-0 lg:pr-4">
                <ButtonLink
                  href={primaryCtaLinkUrl}
                  target={(primaryUrlTarget as '_self' | '_blank') || '_self'}
                  text={primaryCtaLinkText}
                  theme="primary"
                />
              </span>
            )}
            {secondaryCtaLinkText && secondaryCtaLinkUrl && (
              <ButtonLink
                href={secondaryCtaLinkUrl}
                target={(secondaryUrlTarget as '_self' | '_blank') || '_self'}
                text={secondaryCtaLinkText}
                theme="secondary"
              />
            )}
          </>
        )
    }
  }

  return (
    <section>
      <div className="grid lg:grid-cols-2" data-name="hero-block">
        <div
          className={`flex ${
            imagePositionForMobile == ImagePositionForMobile.DEFAULT
              ? 'order-2'
              : 'pb-12'
          } lg:w-10/12`}
        >
          <div className="place-self-center">
            <div>
              <h1 className={`pb-8 lg:pb-10 tracking-btight ${headlineStyle}`}>
                {title}
              </h1>
            </div>
            {teaserCopy && (
              <div className="pb-8 lg:pb-8 fora-text-body-1">
                <RichTextContent richText={teaserCopy} />
              </div>
            )}
            {renderForm()}
            {subFormHeader && (
              <h2 className="mt-10 mb-2 lg:mb-4 fora-text-h6 lg:text-[2rem] lg:tracking-btight lg:leading-tight">
                {subFormHeader}
              </h2>
            )}
            {subFormCopy && <RichTextContent richText={subFormCopy} />}
          </div>
        </div>
        <div
          className={`${
            imagePositionForMobile == ImagePositionForMobile.DEFAULT
              ? 'pb-8'
              : ''
          } lg:order-2 lg:pb-0`}
        >
          <Image
            src={cloudinaryImage?.[0].secure_url ?? image?.url ?? ''}
            alt={
              cloudinaryImage?.[0].metadata?.alt ??
              image?.description ??
              `Fora - ${title}`
            }
            width={976}
            height={1212}
            priority
          />
        </div>
      </div>
    </section>
  )
}
